import i18next from 'i18next';
import i18nextXHRBackend from 'i18next-xhr-backend';

export default function i18n({
  locale,
  baseUrl = '',
}: {
  locale: string;
  baseUrl: string;
}) {
  const i18nextInstance = i18next.createInstance();
  return i18nextInstance.use(i18nextXHRBackend).init({
    lng: locale,
    fallbackLng: 'en',
    keySeparator: '$',
    interpolation: {
      prefix: '{',
      suffix: '}',
      escapeValue: false,
    },
    backend: {
      loadPath: `${baseUrl}assets/locale/messages_{lng}.json`,
      crossDomain: true,
    },
  });
}
